import * as React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { format } from 'date-fns'

interface Props {
  className?: string
  node: {
    lang: string
    title: string
    excerpt: string
    slug: string
    id: string
    date: string
  }
  link?: string
}

const CardItem = (props: Props) => {
  const { node } = props
  const defaultLink = `/${node.slug ? node.slug : node.id}`
  const link = props.link ? props.link : defaultLink

  return (
    <Root className={props.className}>
      <Title
        dangerouslySetInnerHTML={{ __html: node.title }}
        to={link}
      />
      <Excerpt dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      <Footer>
        {
          node.date ? <ItemDate>{format(new Date(node.date), 'dd-MM-yyyy')}</ItemDate> : null
        }
        <Link
          to={link}
          css={{
            color: "red !important",
            textDecoration: "none",
            textAlign: "right",
          }}
        >
          {
            {
              en: "Read More",
              es: "Seguir leyendo",
            }[node.lang]
          }
        </Link>
      </Footer>

    </Root>
  )
}

const Root = styled.div`
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  transition: box-shadow linear 0.218s;
  padding: 14px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);
  }

  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  > :first-child {
    flex: 1;
  }
`

const ItemDate = styled.div``
const Title = styled(Link)`
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
`

const Excerpt = styled.div``
export default CardItem
