import SEO from "../components/seo"
import Layout from "../components/layout"
import React from "react"
import styled from "@emotion/styled"
import ImageGallery from '../components/image-gallery'
import Img from 'gatsby-image'
import textSeparator from '../images/textseparator.png'
import CardItem from './CardItem'
import NewsItem from "../components/news/NewsItem"

const DOCUMENTS_PATH = {
  en: "/documents",
  es: "/documentos",
}
const linkForDocumentPage = (node, lang) => {
  return `/${DOCUMENTS_PATH[lang]}/${lang}/${node.slug}`
}

const DocumentsPage = (props) => {
  const { data, lang } = props.pageContext
  const nodes = data || []
  const title = { es: "Documentos", en: "Documents" }[lang]
  return (
    <StyledLayout lang={lang} pathEn={DOCUMENTS_PATH.en} pathEs={DOCUMENTS_PATH.es} currentPath={DOCUMENTS_PATH[lang]}>
      <SEO title={title} />

      <h1 css={{ marginBottom: 24, textAlign: 'center' }}>{title}</h1>
      {
        nodes.map(documentNode => <CardItem key={documentNode.id} node={documentNode} link={linkForDocumentPage(documentNode, documentNode.lang)}/>)
      }

    </StyledLayout>
  )

}

const StyledLayout = styled(Layout)`
  p {
    margin: 0;
  }
`

const StyledCardItem = styled(CardItem)`
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`
export default DocumentsPage
